import React from 'react';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
//import ContactForm from './components/pages/ContactForm';
import ContactUs from './components/pages/ContactUs';
import Footer from './components/Footer';

function App() {
  return (
    <>
    <Router>
    <Navbar />
    <Switch>
      <Route path='/' exact component={Home} /> 
      <Route path='/contact-us' exact component={ContactUs} /> 
    </Switch>
    <Footer/>
    <Route
            path="/facebook"
            component={() => {
              window.location.replace('https://m.facebook.com/elmaxnetworks');
              return null;
            }}
          />
    </Router>
    </>
  );
}

export default App;

