import React from 'react';
import CardItem from './CardItem';
import './Cards.css';

function Cards() {
  return (
    <div className='cards'>
      <h1>Check Out Our Packages</h1>
      <div className='cards__container'>
          <div className='cards__wrapper'>             
              <ul className='cards__items'>
            <CardItem
              src='images/home-internet.jpg'
              text='Changing how you connect at Home'
              label='Home Package'
              path='/'
            />
            <CardItem
              src='images/office-internet.jpg'
              text='Get connected to the Internet at blazing speeds'
              label='Office Package'
              path='/'
            />
            <CardItem
              src='images/church-internet.jpg'
              text='Bringing the church outside the church walls'
              label='Church Package'
              path='/'
            />
          </ul>
          </div>

      </div>
    </div>
  );
}

export default Cards
