 import React from 'react' ;
 import '../App.css';
 import { Button } from './Button';
 import './HeroSection.css';
 function HeroSection() {
     return (
         <div className='hero-container'>
             <video src="/videos/video-2.mp4" autoPlay loop muted />
             <h1>WE CONNECT YOU TO THE WORLD</h1>
             <p>What are you waiting for?</p>
             <div className='hero-btns'>
                 <Button 
                 className='btns' 
                 buttonStyle='btn--outline'
                 buttonSize='btn--large'
                 >
                     GET CONNECTED
                </Button>
                 <Button 
                 className='btns' 
                 buttonStyle='btn--primary'
                 buttonSize='btn--large'
                 >
                     FREE HOTSPOT <i className='fa-solid fa-wifi'/> 
                </Button>
             </div>

         </div>
     );
 }

 export default HeroSection; 
